@charset "utf-8";

@import 'mixin';
@import 'reset';
@import 'variable';

/* base font
===============================================================*/
/*
10px = 63%   |   17px = 106%   |   28px = 175%   
11px = 69%   |   18px = 113%   |   30px = 188%
12px = 75%   |   19px = 119%   |   32px = 20%
13px = 81%   |   20px = 125%
14px = 88%   |   22px = 138%
15px = 94%   |   24px = 150%
16px = 100%  |   25px = 156%
*/


body {
	margin: 0;
	padding: 0;
	font-family: $fontPrimary;
	color: $primary;
	@include box-sizing(border-box);
	text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-o-text-size-adjust: 100%;
    overflow-x: hidden;
    font-feature-settings: "palt";
    background-image: url(../img/background/bg.png);
    background-position:center, top left;
    background-repeat: repeat, no-repeat;
    font-size: 16px;
}

#AllBox {
    @include transition(all .5s);
}

.cloud {
    position: absolute;
    top: 10px;
    z-index: -1;
    @media screen and (max-width: 950px) {
        display: none;
    }
}


/*BTN FIXED*/
.btnFixed {
    position: fixed;
    right: 0;
    bottom: 100px;
    z-index: 50;
    a {
        @include flexbox;
        @include justify-content(flex-start);
        @include align-items(center);
        width: 200px;
        height: 73px;
        padding: 20px;
        margin-left: auto;
        @include transition(all .5s);
        @media screen and (max-width: 1024px) {
            width: 150px;
            height: 60px;
            padding: 10px;
        }
        @media screen and (max-width: 767px) {
            width: 50px;
            height: 50px;
            @include justify-content(center);
        }
        &.orange {
            background-color: #f37f20;
            @include border-radius(10px 0 0 0);
            @media screen and (max-width: 767px) {
                @include border-radius(5px 0 0 0);
            }
        }
        &.blue {
            background-color: #53b8e9;
            @include border-radius(0 0 0 10px);
            @media screen and (max-width: 767px) {
                @include border-radius(0 0 0 5px);
            }
        }
        div {
            width: 30px;
            @include flexbox;
            @include align-items(center);
            @media screen and (max-width: 1024px) {
                width: 20px;
            }
            @media screen and (max-width: 767px) {
                @include justify-content(center);
            }
            img {
                &.home {
                    height: 22px;
                    @media screen and (max-width: 1024px) {
                        height: 17px;
                    }
                }
                &.contact {
                    height: 15px;
                    @media screen and (max-width: 1024px) {
                        height: 10px;
                    }
                }
            }
        }
        span {
            font-size: 16px;
            color: #FFF;
            font-weight: bold;
            @media screen and (max-width: 1024px) {
                font-size: 12px;
            }
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
        &:hover {
            width: 215px;
            @media screen and (max-width: 1024px) {
                width: 160px;
            }
            @media screen and (max-width: 767px) {
                width: auto;
            }
        }
    }
}


/*HEADER*/
#header {
    background-image: url(../img/topPage/bg-topPage.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    top: 0;
    z-index: 999;
    @include transition(all .15s);
    border-top: 10px solid $blue;
    @media screen and (max-width: 950px) {
        border-top: 5px solid $blue;
        height: 85px;
        border-bottom: 1px dashed #C9C2B2;
    }
    @media screen and (max-width: 950px) {
        background-image: url(../img/background/bg.png);
        background-position:center;
        background-repeat: no-repeat;
        position: fixed;
    }
    &.fixed {
        position: fixed;
    }
    &.scroll {
        background-image: url(../img/topPage/bg-topPageScroll.png);
        .boxFont {
            padding-bottom: 5px !important;
            .fontSize {
                li {
                    width: 20px;
                    height: 20px;
                    a{
                        font-size: 90%;;
                    }
                }
            }
        }
        .contact{
            img{
                width: auto;
                height: 110px !important;
                margin-right: 80px;
            }
        }
        .secondRow{
            margin-top: -20px;
            padding-bottom: 10px;
        }
    }
    .firstRow {
        @media screen and (max-width: 950px) {
            height: 80px;
        }
        .cont {
            @media screen and (max-width: 950px) {
                width: 100%;
                margin: 0;
            }
        }
        .inner {
            @include flexbox;
            @include justify-content(space-between);
            padding-bottom: 15px;
            @media screen and (max-width: 950px) {
                height: 80px;
                padding-bottom: 0;
                @include align-items(center);
            }
            .logo {
                @include flexbox();
                @include flex-direction(row);
                margin-top: 7px;
                width: 260px;
                @media screen and (max-width: 950px) {
                    width: auto;
                    margin-top: 0;
                    padding-left: 15px;
                }
                a {
                    @include flexbox;
                    @include justify-content(flex-start);
                    @include align-items(flex-start);
                    img.img-logo {
                        width: 201px;
                        @media screen and (max-width: 950px) {
                            width: auto;
                            height: 65px;
                        }
                    }
                    .fig_rurichan{
                        width: auto;
                        height: 70px;
                        margin-top: 8px;
                        z-index: 2;
                        @media screen and (max-width: 950px) {
                            width: 50px;
                            height: auto;
                            margin-top: 0px;
                        }
                    }
                }
            }
            .rightSide {
                @include transition(all .5s);
                @include flex(1);
                @include flexbox;
                @include justify-content(flex-end);
                @include align-items(center);
                @media screen and (max-width: 950px) {
                    height: 100%;
                }
                .boxFont {
                    @include transition(all .5s);
                    @include flexbox;
                    @include align-items(center);
                    border-bottom: 2px solid #e2ddd3;
                    padding-bottom: 10px;
                    position: relative;
                    bottom: 25px;
                    left: 25px;
                    @media screen and (max-width: 950px) {
                        display: none;
                    }
                    p {
                        font-weight: bold;
                        font-size: 88%;
                        margin-right: 20px;
                        font-family: $fontMaru;

                    }
                    .fontSize {
                        @include flexbox;
                        @include align-items(center);
                        padding-right: 50px;
                        li {
                            width: 30px;
                            height: 30px;
                            background-color: #FFF;
                            color: #624416;
                            @include flexbox;
                            @include justify-content(center);
                            @include align-items(center);
                            margin: 0 2px;
                            @include border-radius(10px);
                            border: 1px solid #eee7e0;
                            font-family: $fontMaru;
                            &.small {
                                font-size: 17px;
                            }
                            &.middle {
                                font-size: 18px;
                            }
                            &.large {
                                font-size: 20px;
                            }
                            a {
                                width: 100%;
                                height: 100%;
                                font-weight: bold;
                                @include flexbox;
                                @include align-items(center);
                                @include justify-content(center);
                                @include border-radius(10px);
                            }
                            &.button.current {
                                background: $blueLight;
                                border: 1px solid $blueLight;
                                color: #FFF;
                            }
                            &:hover {
                                background: $blueLight;
                                border: 1px solid $blueLight;
                                color: #FFF;
                            }
                        }
                    }
                }
                .contact {
                    position: relative;
                    z-index: 1;
                    @media screen and (max-width: 950px) {
                        display: none;
                    }
                    img {
                        height: 159px;
                        @include transition(all .5s);
                        @media screen and (max-width: 1024px) {
                            height: 120px;
                        }
                    }
                }
                .iconNav {
                    display: none;
                    @media screen and (max-width: 950px) {
                        @include flexbox;
                        @include flex-direction(column);
                        @include justify-content(center);
                        @include align-items(center);
                        height: 50px;
                        width: 50px;
                        margin-right: 15px;
                        background-color: $blue;
                        @include border-radius(5px);
                    }
                    .hamburger {
                        padding: 0;
                        @include flexbox;
                        @include flex-direction(column);
                        @include align-items(center);
                        position: relative;
                        &:focus {
                            outline: none;
                        }
                        &:hover {
                            @include opacity(1);
                        }
                        .hamburger-box {
                            width: 30px;
                            .hamburger-inner {
                                @include border-radius(0);
                                width: 30px;
                                height: 3px;
                                background-color: #FFF;
                                &:before {
                                    @include border-radius(0);
                                    width: 30px;
                                    top: -10px;
                                    height: 3px;
                                    background-color: #FFF;
                                }
                                &:after {
                                    @include border-radius(0);
                                    width: 30px;
                                    height: 3px;
                                    background-color: #FFF;
                                }
                            }
                        }
                        &.hamburger--collapse {
                            .hamburger-inner {
                                &:after {
                                    top: -20px;
                                }
                            }
                        }
                        &.is-active {
                            &:hover {
                                @include opacity(1);
                            }
                            .hamburger-inner {
                                &:before {
                                    top: 0;
                                }
                                &:after {
                                    top: 0;
                                }
                            }
                            .textMenu {
                                display: none;
                            }
                            .textClose {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .secondRow {
        padding-bottom: 45px;
        @include transition(all .5s);
        @media screen and (max-width: 950px) {
            display: none;
        }
        .navigation {
            ul {
                @include transition(all .5s);
                @include flexbox;
                @include align-items(center);
                li {
                    width: 20%;
                    a {
                        display: block;
                        width: 100%;
                        text-align: center;
                        border-right: 1px dashed #C9C2B2;
                        font-size: 119%;
                        font-weight: bold;
                        font-family: vdl-v7marugothic, sans-serif;
                        color: $title;
                        @media screen and (max-width: 1024px) {
                            font-size: 100%;
                        }
                    }
                    &.big {
                        width: 250px;
                    }
                    &:last-child {
                        a {
                            border-right: 0;
                        }
                    }
                }
            }
        }
    }
}

/* NAV SP */
.navSp {
    display: none;
    @media screen and (max-width: 950px) {
        position: fixed;
        top: 85px;
        background: url(../img/background/bg.png);
        width: 100%;
        height: 100%;
        z-index: 100;
        padding: 0 0 100px;
        overflow-y: auto;
    }
    ul.firstRow {
        width: 100%;
        margin-bottom: 30px;
        li {
            width: 100%;
            line-height: 50px;
            position: relative;
            border-bottom: 1px dashed #C9C2B2;
            a {
                display: block;
                position: relative;
                font-size: 100%;
                font-weight: bold;
                padding: 0 15px;
                color: $title;
                i {
                    position: absolute;
                    right: 15px;
                    top: 18px;
                    font-size: 13px;
                    &.up {
                        display: none;
                    }
                }
            }
            .subMenu {
                display: none;
                width: 100%;
                li {
                    line-height: 1;
                    &:first-child {
                        border-top: 1px dashed #c4c4c4;
                    }
                    &:last-child {
                        border-bottom: 0;
                    }
                    a {
                        padding: 15px;
                        padding-left: 25px;
                        width: 100%;
                        display: block;
                        position: relative;
                    }
                }
            }
            .nav-change {
                @include border-radius(11px 11px 0 0);
                i {
                    &.down {
                        display: none;
                    }
                    &.up {
                        display: block;
                    }
                }
            }
        }
    }
    .telp {
        a {
            text-align: center;
            display: block;
            img {
                width: 320px;
            }
        }
    }
}


#footer {
    .illust {
        img {
            width: 100%;
            &.PC {
                @media screen and (max-width: 640px) {
                    display: none;
                }
            }
            &.SP {
                display: none;
                @media screen and (max-width: 640px) {
                    display: block;
                }
            }
        }
    }
    .inner {
        @include flexbox;
        @include justify-content(space-between);
        padding: 50px 30px 10px;
        @media screen and (max-width: 1024px) {
            padding: 50px 0 10px;
        }
        @media screen and (max-width: 950px) {
            @include flex-wrap(wrap);
        }
        .navigation {
            @include flex(1);
            @include flexbox;
            @media screen and (max-width: 950px) {
                @include flex(unset);
                width: 100%;
                @include justify-content(space-between);
                margin-bottom: 30px;
            }
            @media screen and (max-width: 767px) {
                display: none;
            }
            ul {
                @media screen and (max-width: 950px) {
                    width: 50%;
                }
                &:first-child {
                    margin-right: 30px;
                    @media screen and (max-width: 950px) {
                        margin-right: 0;
                    }
                }
                li {
                    a {
                        color: $title;
                        line-height: 2;
                        &.child {
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
        .rightSide {
            width: 300px;
            @media screen and (max-width: 950px) {
                margin: 0 auto 0;
            }
            .tel {
                margin-bottom: 10px;
                @media screen and (max-width: 950px) {
                    text-align: center;
                }
            }
            .info {
                margin-bottom: 15px;
                .name {
                    font-size: 113%;
                    font-weight: bold;
                    color: $title;
                }
                .address {
                    color: $title;
                    @media screen and (max-width: 950px) {
                        text-align: center;
                    }
                }
            }
            .banner {
                @media screen and (max-width: 950px) {
                    text-align: center;
                }
                a {
                    display: block;
                    margin-bottom: 5px;
                    img {
                        width: 200px;
                    }
                }
            }
        }
    }
    #banner_box {
        max-width: 865px;
        width: 100%;
        margin: 15px auto 30px;
        padding-top: 30px;
        padding-bottom: 20px;
        background-color: rgba(121,98,90,0.4);
        @media screen and (max-width: 767px) {
            padding: 3% 3% 0;
        }
        ul {
            @include flexbox;
            @include justify-content(space-between);
            @include flex-wrap(wrap);
            max-width: 578px;
            width: 100%;
            margin: auto;
            li {
                width: 186px;
                height: 56px;
                margin-bottom: 10px;
                @media screen and (max-width: 767px) {
                    width: 48.5%;
                    margin-bottom: 3%;
                    height: auto;
                }
                a {
                    img {
                        @media screen and (max-width: 767px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .copyright {
        background-color: $blue;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        position: relative;
        .link-policy{
            position: absolute;
            width: 100%;
            left: 0;
            top: auto;
            a{
                color: #FFF;
            }
        }
        p {
            color: #FFF;
            font-size: 88%;
            padding: 17px 0;
            position: relative;
            z-index: 5;
            text-align: center;
            @media screen and (max-width: 950px){
                padding: 10px 0;
            }
        }
    }
}

#mainvisual {
    margin-top: 285px;
    @media screen and (max-width: 950px) {
        margin-top: 85px;
    }
    &.topPage {
        .inner {
            img {
                width: 100%;
            }
            .PC {
                @media screen and (max-width:767px) {
                    display: none;
                }
            }
            .SP {
                display: none;
                @media screen and (max-width: 767px) {
                    display: block;
                }
            }
        }
    }
}

.subMainvisual {
    padding: 30px 0;
    border-bottom: 2px dashed #C9C2B2;
    @media screen and (max-width: 950px) {
        margin-top: 85px;
    }
    .inner {
        @include flexbox;
        @include justify-content(center);
        .mv {
            position: relative;
            img {
                position: relative;
            }
            .text {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                @include flexbox;
                @include justify-content(center);
                @include align-items(center);
                h2 {
                    font-family: $fontMaru;
                    font-size: 412%;
                    font-weight: bold;
                    letter-spacing: 2px;
                    color: #624417;
                    word-break: keep-all;
                    @media screen and (max-width: 767px) {
                        font-size: 250%;
                    }
                    @media screen and (max-width: 480px) {
                        font-size: 150%;
                    }
                }
                &.mainvisual-tenryuqawa{
                    width: 120%;
                    left: -10%;
                    h2{
                        @media screen and (max-width: 950px) {
                            font-size: 300%;
                        }
                        @media screen and (max-width: 750px) {
                            font-size: 200%;
                        }
                        @media screen and (max-width: 480px) {
                            font-size: 140%;
                        } 
                        @media screen and (max-width: 360px) {
                            font-size: 130%;
                        }  
                    }
                }
            }
        }
    }
}

/*-----BREADCRUMB-----*/
.breadcrumbPage {
    margin-top: 270px;
    height: 40px;
    overflow: hidden;
    @include flexbox;
    @include align-items(center);
    background-color: #FFF;
    font-family: $fontMaru;
    @media screen and (max-width: 950px) {
        margin-top: 85px;
        display: none;
    }
    @media screen and (max-width: 767px) {
        height: auto;
        padding: 8px 15px;
    }
    ul {
        @include flexbox;
        @media screen and (max-width: 480px) {
            @include flex-wrap(wrap);
        }
        li {
            font-size: 75%;
            letter-spacing: 1px;
            color: #624417;
            margin-right: 40px;
            a { 
                img {
                    height: 18px;
                    position: relative;
                    top: -2px;
                }
                &.active {
                    border-bottom: 1px solid #624417;
                    padding-bottom: 3px;
                }
            }
            &.arrow {
                position: relative;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: -25px;
                    display: block;
                    content: '';
                    height: 8px;
                    width: 8px;
                    border: 1px solid #888;
                    border-left-width: 0;
                    border-top-width: 0;
                    -webkit-transform: translate(0, -50%) rotate(-45deg);
                    -ms-transform: translate(0, -50%) rotate(-45deg);
                    transform: translate(0, -50%) rotate(-45deg);
                }
            }
        }
    }
    
}

.subTitle {
    background-color: #f37f20;
    color: #FFF;
    font-size: 113%;
    font-weight: bold;
    @include border-radius(10px);
    padding: 5px 20px;
    margin-bottom: 15px;
}

.btnBox {
    @include flexbox;
    @include justify-content(center);
    .button {
        background-color: $bgButton;
        border: 1px solid $bgButton;
        color: #FFF;
        font-size: 113%;
        font-weight: bold;
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        @include border-radius(10px);
        width: 338px;
        height: 46px;
        position: relative;
        @include transition(all .5s);
        i {
            position: absolute;
            left: 18px;
        }
        &:hover {
            background-color: #FFF;
            color: $bgButton;
        }
    }
}


#main {
    /*TOP PAGE*/
    &.topPage {
        #sliderRss {
            .heading {
                text-align: center;
                font-size: 206%;
                font-weight: bold;
                line-height: 1;
                color: $title;
                margin: 50px 0;
                font-family: $fontMaru;
                letter-spacing: 2px;
                @media screen and (max-width: 767px) {
                    font-size: 188%;
                    margin: 30px 0 50px;
                }
            }
            .inner {
                .sliderTop {
                    display: none;
                    .caroufredsel_wrapper {
                        width: 100%!important;
                    }
                    #carousel {
                        position: relative;
                        z-index: 1;
                        width: 100%;
                        overflow: hidden;
                        .item {
                            width: 250px;
                            float: left;
                            position: relative;
                            overflow: hidden;
                            margin-right: 15px;
                            background-image: url(../img/background/bg.png);
                            @include border-radius(10px);
                            @media screen and (max-width: 1024px) {
                                width: 300px;
                            }
                            @media screen and (max-width: 767px) {
                                width: 180px;
                                margin-right: 10px;
                            }
                            a {
                                .img {
                                    width: 100%;
                                    height: 160px;
                                    @include border-radius(10px 10px 0 0);
                                    @media screen and (max-width: 1024px) {
                                        height: 200px;
                                    }
                                    @media screen and (max-width: 767px) {
                                        height: 100px;
                                    }
                                }
                                .content {
                                    padding: 10px;
                                    background: rgba(255, 255, 255, 0.9);
                                    width: 100%;
                                    h2 {
                                        font-size: 88%;
                                        color: $title;
                                        overflow: hidden;
                                        max-height: 42px;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        /* FIRST ROW */
        .firstRow {
            padding: 80px 0 0;
            @media screen and (max-width: 1024px) {
                padding: 60px 0 0;
            }
            @media screen and (max-width: 767px) {
                padding: 50px 0 0;
            }
            .inner {
                .heading {
                    text-align: center;
                    font-size: 206%;
                    font-weight: bold;
                    line-height: 1;
                    color: $title;
                    margin-bottom: 40px;
                    font-family: $fontMaru;
                    letter-spacing: 2px;
                    @media screen and (max-width: 767px) {
                        font-size: 188%;
                    }
                }
                .list {
                    @include flexbox;
                    @include justify-content(center);
                    @include flex-wrap(wrap);
                    margin-bottom: 15px;
                    @media screen and (max-width: 767px) {
                        @include justify-content(space-between);
                    }
                    .item {
                        width: 280px;
                        margin: 0 30px 30px;
                        @media screen and (max-width: 1120px){
                            width: 30%;
                            margin: 0 1.5% 30px;
                        }
                        @media screen and (max-width: 767px) {
                            width: 47.5%;
                            margin: 0 0 5%;
                        }
                    }
                }
                .btnBox {
                    @include flexbox;
                    @include justify-content(center);
                }
            }
        }
        /*SECOND ROW*/
        .secondRow {
            padding: 80px 0;
            @media screen and (max-width: 1024px) {
                padding: 60px 0;
            }
            @media screen and (max-width: 767px) {
                padding: 50px 0;
            }
            .inner {
                .heading {
                    text-align: center;
                    font-size: 206%;
                    font-weight: bold;
                    line-height: 1;
                    color: $title;
                    margin-bottom: 40px;
                    font-family: $fontMaru;
                    letter-spacing: 2px;
                    @media screen and (max-width: 767px) {
                        font-size: 188%;
                    }
                }
                .map {
                    @include flexbox;
                    @include justify-content(flex-start);
                    @include align-items(center);
                    padding: 0 40px;
                    height: auto;
                    @media screen and (max-width: 1120px){
                        padding: 0;
                    }
                    @media screen and (max-width: 950px) {
                        @include flex-direction(column);
                    }
                    .map-facility{
                        width: 100%;
                        max-width: 498px;
                        max-height: 488px;
                        height: auto;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 30px;
                        @media screen and (max-width: 950px) {
                            margin-bottom: 30px;
                            margin-top: 10px;
                        }
                    } 
                    .text {
                        @include flex(1);
                        padding: 0 80px;
                        @media screen and (max-width: 1120px){
                            padding: 0;
                        }
                        @media screen and (max-width: 767px) {
                            @include flex(unset);
                            padding-top: 30px;
                        }
                        p {
                            color: $title;
                            font-size: 138%;
                            line-height: 2;
                            text-align: justify;
                            @media screen and (max-width: 950px){
                                font-size: 113%;
                            }
                            @media screen and (max-width: 480px) {
                                font-size: 100%;
                            }
                        }
                    }
                }
            }
        }
        /*THIRD ROW*/
        .thirdRow {
            padding-bottom: 60px;
            .inner {
                padding: 0 40px;
                @media screen and (max-width: 950px){
                    padding: 0 30px;
                }
                #feature {
                    position: relative;
                    margin-bottom: 45px;
                    .owl-item {
                        position: relative;
                        .item {
                            width: 100%;
                            position: relative;
                            img {
                                @include border-radius(10px);
                            }
                            span {
                                left: 0;
                                top: 0;
                                position: absolute;
                                width: 120px;
                                height: 30px;
                                color: #000000;
                                @include border-radius(10px 0 10px 0);
                                @include flexbox;
                                @include justify-content(center);
                                @include align-items(center);

                                &.ungu-bias {
                                    background-color: #F4C9E1;
                                }
                                &.hijau {
                                    background-color: #A0EAA9;
                                }
                                &.kuning {
                                    background-color: #FFE579;
                                }
                                &.ungu {
                                    background-color: #CDBED8;
                                }
                                &.biru {
                                    background-color: #A2ECF4;
                                }
                                &.orange {
                                    background-color: #FFC888;
                                }
                                &.abu {
                                    background-color: #D9CCB8;
                                }
                                &.pink {
                                    background-color: #FFB1BD;
                                }
                                @media screen and (max-width: 480px) {
                                    width: 70px;
                                    height: 20px;
                                    font-size: 69%;
                                }
                            }
                        }
                    }
                    .owl-nav {
                        position: absolute;
                        width: 100%;
                        margin: 0;
                        top: 50%;
                        .owl-prev {
                            position: absolute;
                            left: -40px;
                            @media screen and (max-width: 950px){
                                left: -25px;
                            }
                        }
                        .owl-next {
                            position: absolute;
                            right: -40px;
                            @media screen and (max-width: 950px){
                                right: -25px;
                            }
                        }
                        button {
                            margin: 0;
                            i {
                                font-size: 30px;
                                color: $blue;
                                @media screen and (max-width: 950px){
                                    font-size: 22px;
                                }
                            }
                            &:hover {
                                background: transparent;
                            }
                            &:focus {
                                outline: 0;
                            }
                        }
                    }
                }
            }
            .btnBox {
                @include flexbox;
                @include justify-content(center);
            }
        }
    }
    /*PRIVACY-POLICY*/
    &.privacy-policy {
        padding: 50px 0 90px;
        @media screen and (max-width: 767px) {
            padding: 30px 0 50px;
        }
        .inner {
            background-color: #FFF;
            max-width: 900px;
            width: 100%;
            margin: auto;
            padding: 30px;
            @include border-radius(15px);
            letter-spacing: 1px;
            @media screen and (max-width: 767px) {
                padding: 5%;
            }
            p {
                line-height: 2;
                @media screen and (max-width: 767px) {
                    line-height: 1.7;
                }
            }
            .item {
                margin-bottom: 30px;
            }
        }
    }
    /*steps*/
    &.flow {
        padding: 50px 0 90px;
        @media screen and (max-width: 767px) {
            padding: 30px 0 50px;
        }
        .hiddenSp {
            @media screen and (max-width: 700px) {
                display: none;
            }
        }
        .showSp {
            display: none;
            @media screen and (max-width: 700px) {
                display: block;
            }
        }
        .inner {
            max-width: 900px;
            width: 100%;
            margin: auto;
            .headingBox {
                @include flexbox;
                @include justify-content(space-between);
                @media screen and (max-width: 700px) {
                    display: none;
                }
                .heading {
                    width: 46.667%;
                    height: 73px;
                    @include border-radius(10px 10px 0 0);
                    @include flexbox;
                    @include justify-content(center);
                    @include align-items(center);
                    color: #FFF;
                    font-size: 20px;
                    font-weight: bold;
                    font-family: $fontMaru;
                    text-align: center;
                    line-height: 1.5;
                    letter-spacing: 3px;
                    @media screen and (max-width: 767px) {
                        font-size: 18px;
                    }
                    &.green {
                        background-color: #87B828;
                    }
                    &.blue {
                        background-color: #53B8E9;
                    }
                }
            }
            .outer {
                @include flexbox;
                @include justify-content(space-between);
                .box {
                    width: 46.667%;
                    @include border-radius(0 0 10px 10px);
                    padding: 20px;
                    padding-bottom: 30px;
                    position: relative;
                    &.green {
                        background-color: #CFE3A9;
                        @media screen and (max-width: 700px) {
                            background-color: transparent;
                        }
                    }
                    &.blue {background-color: #BAE3F6;}
                    @media screen and (max-width: 700px) {
                        width: 100%;
                        @include border-radius(10px);
                        padding: 0;
                    }
                    .onSp {
                        display: none;
                        @media screen and (max-width: 700px) {
                            display: block;
                            position: absolute;
                            padding: 5px 20px;
                            @include border-radius(30px);
                            text-align: center;
                            color: #FFF;
                            font-weight: bold;
                            font-size: 12px;
                            &.green {
                                background-color: #87B828;;
                                width: 199px;
                                height: 46px;
                                color: white;
                                z-index: 3;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 25px;
                                font-size: 11px;
                                margin-left: 125px;
                                margin-top: -35px;
                            }
                            &.blue {
                                background-color: #53B8E9;
                                width: 199px;
                                height: 46px;
                                color: white;
                                z-index: 4;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 25px;
                                font-size: 11px;
                                margin-left: 125px;
                                margin-top: -20px;
                            }
                            &.left {
                                top: 15px;
                                left: 0;
                            }
                            &.right {
                                right: 0;
                                top: 15px;
                            }
                        }
                    }
                    .step1 {
                        text-align: center;
                        position: relative;
                        margin-top: 20px;
                        .content-step{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 295px;
                            width: 100%;
                            margin-left: 0px;
                            margin-right: 0px;
                            .background-step{
                                width: 100%;
                                height: 100%;
                                background-image: url("../img/flow/background/step1.png");
                                background-size: 100%;
                                background-repeat: no-repeat;
                                position: relative;
                                .circle-number{
                                    background-color: #F37F20;
                                    width: 100px;
                                    height: 100px;
                                    border-radius: 50px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-left: 60%;
                                    margin-top: -10px;
                                    position: absolute;
                                    span{
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        font-family: 'M PLUS Rounded 1c', sans-serif;
                                        font-weight: bold;
                                        color: white;
                                    }
                                    span.num-1{
                                        margin-top: 20px;
                                        font-size: 24px;
                                        line-height: 10px;
                                    }
                                    span.num-2{
                                        font-size: 36px;
                                    }
                                }
                                // title
                                .title{
                                    color: #F37F20;
                                    font-size: 23px;
                                    font-weight: 600;
                                    width: 165px;
                                    border-bottom: 1px dashed #EEEEEE;
                                    text-align: left;
                                    margin-top: 20px;
                                    margin-left: 50px;
                                    padding: 15px 0px;
                                }
                                // desc
                                .desc{
                                    margin: 17px 50px 0px 50px;
                                    text-align: left;
                                    font-size: 16px;
                                }
                            }
                        }
                        // tablet step1
                        @media screen and (max-width: 768px){
                            .content-step{
                                height: 210px;
                                .background-step{
                                    .circle-number{
                                        width: 80px;
                                        height: 80px;
                                        span.num-1{
                                            font-size: 18px;
                                        }
                                        span.num-2{
                                            font-size: 24px;
                                        }
                                    }
                                    // title
                                    .title{
                                        color: #F37F20;
                                        font-size: 21px;
                                        width: 135px;
                                        margin-left: 32px;
                                        padding: 10px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 10px 32px 0px 32px;
                                        text-align: left;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                        // mobile step1
                        // 700
                        @media screen and (max-width: 700px){
                            .content-step{
                                height: 395px;
                                width: 100%;
                                background-color: #CFE3A9;
                                padding: 10px;
                                border-radius: 10px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 75%;
                                    height: 100%;
                                    .circle-number{
                                        width: 100px;
                                        height: 100px;
                                        margin-left: -8%;
                                        margin-top: -75px;
                                    }
                                    // title
                                    .title{
                                        font-size: 24px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 50px;
                                        margin-left: 58px;
                                        padding: 10px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 58px 0px 58px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 630
                        @media screen and (max-width: 630px){
                            .content-step{
                                height: 375px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 82%;
                                    // title
                                    .title{
                                        margin-top: 40px;
                                    }
                                }
                            }
                        }
                        // 550
                        @media screen and (max-width: 550px){
                            .content-step{
                                height: 375px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 88%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 500
                        @media screen and (max-width: 500px){
                            .content-step{
                                height: 375px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 95%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 460
                        @media screen and (max-width: 480px){
                            .content-step{
                                height: 365px;
                                .background-step{
                                    width: 100%;
                                    .circle-number{
                                        margin-left: -6%;
                                    }
                                }
                            }
                        }
                        // 430
                        @media screen and (max-width: 430px){
                            .content-step{
                                height: 345px;
                                .background-step{
                                    // title
                                    .title{
                                        font-size: 24px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 30px;
                                        margin-left: 42px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 42px 0px 42px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 400
                        @media screen and (max-width: 400px){
                            margin-top: 40px;
                            .content-step{
                                height: 300px;
                                .background-step{
                                    // title
                                    .circle-number{
                                        width: 95px;
                                        height: 95px;
                                        margin-left: -7%;
                                        margin-top: -85px;
                                    }
                                    .title{
                                        font-size: 21px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 20px;
                                        margin-left: 38px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 38px 0px 38px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        // 350
                        @media screen and (max-width: 350px){
                            margin-top: 40px;
                            .content-step{
                                height: 265px;
                                .background-step{
                                    // title
                                    .circle-number{
                                        width: 87px;
                                        height: 87px;
                                        margin-left: -8%;
                                        margin-top: -85px;
                                    }
                                    .title{
                                        font-size: 21px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 10px;
                                        margin-left: 33px;
                                        padding: 5px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 33px 0px 33px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        //
                        // @media screen and (max-width: 00px){
                        //     .content-step{
                        //         height: 275px;
                        //         width: 100%;
                        //         background-color: #CFE3A9;
                        //         padding: 10px;
                        //         border-radius: 10px;
                        //         .background-step{
                        //             margin-top: 40px;
                        //             .circle-number{
                        //                 width: 80px;
                        //                 height: 80px;
                        //                 margin-left: -8%;
                        //                 margin-top: -70px;
                        //             }
                        //             // title
                        //             .title{
                        //                 font-size: 21px;
                        //                 width: 165px;
                        //                 text-align: left;
                        //                 margin-top: 20px;
                        //                 margin-left: 38px;
                        //                 padding: 10px 0px;
                        //             }
                        //             // desc
                        //             .desc{
                        //                 margin: 5px 38px 0px 38px;
                        //                 text-align: left;
                        //                 font-size: 14px;
                        //             }
                        //         }
                        //     }
                        // }

                        //baris baru
                        .telp {
                            margin: 30px 0 15px;
                        }
                        .arrow {
                            margin-top: 15px;
                            text-align: center;
                        }
                    }
                    .step2 {
                        text-align: center;
                        position: relative;
                        .content-step{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 211px;
                            width: 100%;
                            margin-left: 0px;
                            margin-right: 0px;
                            .background-step{
                                width: 100%;
                                height: 100%;
                                background-image: url("../img/flow/background/step2.png");
                                background-size: 100%;
                                background-repeat: no-repeat;
                                position: relative;
                                .circle-number{
                                    background-color: #F37F20;
                                    width: 100px;
                                    height: 100px;
                                    border-radius: 50px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-left: 55%;
                                    margin-top: -10px;
                                    position: absolute;
                                    span{
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        font-family: 'M PLUS Rounded 1c', sans-serif;
                                        font-weight: bold;
                                        color: white;
                                    }
                                    span.num-1{
                                        margin-top: 20px;
                                        font-size: 24px;
                                        line-height: 10px;
                                    }
                                    span.num-2{
                                        font-size: 36px;
                                    }
                                }
                                // title
                                .title{
                                    color: #F37F20;
                                    font-size: 23px;
                                    font-weight: 600;
                                    width: 151px;
                                    border-bottom: 1px dashed #EEEEEE;
                                    text-align: left;
                                    margin-top: 30px;
                                    margin-left: 50px;
                                    padding: 15px 0px;
                                }
                                // desc
                                .desc{
                                    margin: 17px 50px 0px 50px;
                                    text-align: left;
                                    font-size: 16px;
                                }
                            }
                        }
                        // tablet step2
                        @media screen and (max-width: 768px){
                            .content-step{
                                height: 155px;
                                .background-step{
                                    .circle-number{
                                        width: 80px;
                                        height: 80px;
                                        margin-left: 58%;
                                        margin-top: -10px;
                                        span.num-1{
                                            font-size: 18px;
                                        }
                                        span.num-2{
                                            font-size: 24px;
                                        }
                                    }
                                    // title
                                    .title{
                                        color: #F37F20;
                                        font-size: 21px;
                                        width: 151px;
                                        margin-top: 20px;
                                        margin-left: 32px;
                                        padding: 10px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 10px 32px 0px 32px;
                                        text-align: left;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                        // mobile step2
                        @media screen and (max-width: 700px){
                            margin-top: 40px;
                            .content-step{
                                height: 295px;
                                width: 100%;
                                background-color: #CFE3A9;
                                padding: 10px;
                                border-radius: 10px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 75%;
                                    height: 100%;
                                    .circle-number{
                                        width: 100px;
                                        height: 100px;
                                        margin-left: -8%;
                                        margin-top: -75px;
                                    }
                                    // title
                                    .title{
                                        font-size: 24px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 50px;
                                        margin-left: 58px;
                                        padding: 10px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 58px 0px 58px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 630
                        @media screen and (max-width: 630px){
                            .content-step{
                                height: 295px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 82%;
                                    // title
                                    .title{
                                        margin-top: 40px;
                                    }
                                }
                            }
                        }
                        // 550
                        @media screen and (max-width: 550px){
                            .content-step{
                                height: 295px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 88%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 500
                        @media screen and (max-width: 500px){
                            .content-step{
                                height: 295px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 95%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 480
                        @media screen and (max-width: 480px){
                            .content-step{
                                height: 275px;
                                .background-step{
                                    width: 100%;
                                    .circle-number{
                                        margin-left: -6%;
                                    }
                                }
                            }
                        }
                        // 430
                        @media screen and (max-width: 430px){
                            .content-step{
                                height: 255px;
                                .background-step{
                                    // title
                                    .title{
                                        font-size: 24px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 30px;
                                        margin-left: 42px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 42px 0px 42px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 400
                        @media screen and (max-width: 400px){
                            .content-step{
                                height: 220px;
                                .background-step{
                                    // title
                                    .circle-number{
                                        width: 95px;
                                        height: 95px;
                                        margin-left: -7%;
                                        margin-top: -85px;
                                    }
                                    .title{
                                        font-size: 21px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 20px;
                                        margin-left: 38px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 38px 0px 38px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        // 350
                        @media screen and (max-width: 350px){
                            margin-top: 40px;
                            .content-step{
                                height: 190px;
                                .background-step{
                                    // title
                                    .circle-number{
                                        width: 87px;
                                        height: 87px;
                                        margin-left: -8%;
                                        margin-top: -85px;
                                    }
                                    .title{
                                        font-size: 21px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 10px;
                                        margin-left: 33px;
                                        padding: 5px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 33px 0px 33px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        //baris baru
                        .arrow {
                            margin-top: 10px;
                            position: relative;
                            @media screen and (max-width: 700px) {
                                @include flexbox;
                                @include justify-content(center);
                            }
                            .right {
                                position: absolute;
                                top: 0;
                                left: 50%;
                                transform: translate(-4%, 0%);
                                z-index: 3;
                                @media screen and (max-width: 950px) {
                                    width: 80%;
                                }
                            }
                        }
                    }
                    .step3 {
                        text-align: center;
                        position: relative;
                        margin-top: 0px;
                        .content-step{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 250px;
                            width: 100%;
                            margin-left: 0px;
                            margin-right: 0px;
                            .background-step{
                                width: 100%;
                                height: 100%;
                                background-image: url("../img/flow/background/step3.png");
                                background-size: 100%;
                                background-repeat: no-repeat;
                                position: relative;
                                .circle-number{
                                    background-color: #F37F20;
                                    width: 100px;
                                    height: 100px;
                                    border-radius: 50px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-left: 3%;
                                    margin-top: -35px;
                                    position: absolute;
                                    span{
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        font-family: 'M PLUS Rounded 1c', sans-serif;
                                        font-weight: bold;
                                        color: white;
                                    }
                                    span.num-1{
                                        margin-top: 20px;
                                        font-size: 24px;
                                        line-height: 10px;
                                    }
                                    span.num-2{
                                        font-size: 36px;
                                    }
                                }
                                // title
                                .title{
                                    color: #F37F20;
                                    font-size: 23px;
                                    font-weight: 600;
                                    width: 262px;
                                    border-bottom: 1px dashed #EEEEEE;
                                    text-align: right;
                                    margin-top: 50px;
                                    margin-left:50px;
                                    padding: 15px 0px;
                                }
                                // desc
                                .desc{
                                    margin: 17px 60px 0px 50px;
                                    text-align: left;
                                    font-size: 16px;
                                }
                            }
                        }
                        // tablet step3
                        @media screen and (max-width: 768px){
                            margin-top: -20px;
                            .content-step{
                                height: 180px;
                                .background-step{
                                    .circle-number{
                                        width: 80px;
                                        height: 80px;
                                        margin-left: 3%;
                                        margin-top: -35px;
                                        span.num-1{
                                            font-size: 18px;
                                        }
                                        span.num-2{
                                            font-size: 24px;
                                        }
                                    }
                                    // title
                                    .title{
                                        color: #F37F20;
                                        font-size: 21px;
                                        width: 242px;
                                        text-align: right;
                                        margin-top: 35px;
                                        margin-left: 15px;
                                        padding: 10px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 10px 38px 0px 38px;
                                        text-align: left;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                        // mobile step3
                        @media screen and (max-width: 700px){
                            margin-top: 40px;
                            .blue {
                                margin-top: -35px;
                            }
                            .content-step{
                                height: 345px;
                                width: 100%;
                                background-color: #BAE3F6;
                                padding: 10px;
                                border-radius: 10px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 75%;
                                    height: 100%;
                                    .circle-number{
                                        width: 100px;
                                        height: 100px;
                                        margin-left: -8%;
                                        margin-top: -75px;
                                    }
                                    // title
                                    .title{
                                        font-size: 24px;
                                        width: 265px;
                                        text-align: left;
                                        margin-top: 50px;
                                        margin-left: 58px;
                                        padding: 10px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 58px 0px 58px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 630
                        @media screen and (max-width: 630px){
                            .content-step{
                                height: 345px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 82%;
                                    // title
                                    .title{
                                        margin-top: 40px;
                                    }
                                }
                            }
                        }
                        // 550
                        @media screen and (max-width: 550px){
                            .content-step{
                                height: 345px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 88%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 500
                        @media screen and (max-width: 500px){
                            .content-step{
                                height: 345px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 95%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 480
                        @media screen and (max-width: 480px){
                            .content-step{
                                height: 325px;
                                .background-step{
                                    width: 100%;
                                    .circle-number{
                                        margin-left: -6%;
                                    }
                                }
                            }
                        }
                        // 430
                        @media screen and (max-width: 430px){
                            .content-step{
                                height: 305px;
                                .background-step{
                                    // title
                                    .title{
                                        font-size: 24px;
                                        text-align: left;
                                        margin-top: 30px;
                                        margin-left: 42px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 42px 0px 42px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 400
                        @media screen and (max-width: 400px){
                            .content-step{
                                height: 250px;
                                .background-step{
                                    // title
                                    .circle-number{
                                        width: 95px;
                                        height: 95px;
                                        margin-left: -7%;
                                        margin-top: -85px;
                                    }
                                    .title{
                                        font-size: 21px;
                                        width: 250px;
                                        text-align: left;
                                        margin-top: 20px;
                                        margin-left: 38px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 38px 0px 38px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        // 350
                        @media screen and (max-width: 350px){
                            margin-top: 40px;
                            .content-step{
                                height: 225px;
                                .background-step{
                                    // title
                                    .circle-number{
                                        width: 87px;
                                        height: 87px;
                                        margin-left: -8%;
                                        margin-top: -85px;
                                    }
                                    .title{
                                        font-size: 21px;
                                        width: 220px;
                                        text-align: left;
                                        margin-top: 10px;
                                        margin-left: 33px;
                                        padding: 5px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 33px 0px 33px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        // baris baru
                        .arrow {
                            margin-top: 5px;
                        }
                    }
                    .step4 {
                        margin-top: -10px;
                        text-align: center;
                        position: relative;
                        .content-step{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 210px;
                            width: 100%;
                            margin-left: 0px;
                            margin-right: 0px;
                            .background-step{
                                width: 100%;
                                height: 100%;
                                background-image: url("../img/flow/background/step4.png");
                                background-size: 100%;
                                background-repeat: no-repeat;
                                position: relative;
                                .circle-number{
                                    background-color: #F37F20;
                                    width: 100px;
                                    height: 100px;
                                    border-radius: 50px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-left: 12%;
                                    margin-top: 0px;
                                    position: absolute;
                                    span{
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        font-family: 'M PLUS Rounded 1c', sans-serif;
                                        font-weight: bold;
                                        color: white;
                                    }
                                    span.num-1{
                                        margin-top: 20px;
                                        font-size: 24px;
                                        line-height: 10px;
                                    }
                                    span.num-2{
                                        font-size: 36px;
                                    }
                                }
                                // title
                                .title{
                                    color: #F37F20;
                                    font-size: 23px;
                                    font-weight: 600;
                                    width: 156px;
                                    border-bottom: 1px dashed #EEEEEE;
                                    text-align: left;
                                    margin-top: 30px;
                                    margin-left:43%;
                                    padding: 15px 0px;
                                }
                                // desc
                                .desc{
                                    margin: 17px 60px 0px 50px;
                                    text-align: left;
                                    font-size: 16px;
                                }
                            }
                        }
                        // tablet step4
                        @media screen and (max-width: 768px){
                            .content-step{
                                height: 165px;
                                margin-top: 10px;
                                .background-step{
                                    .circle-number{
                                        width: 80px;
                                        height: 80px;
                                        margin-left: 12%;
                                        margin-top: -15px;
                                        span.num-1{
                                            font-size: 18px;
                                        }
                                        span.num-2{
                                            font-size: 24px;
                                        }
                                    }
                                    // title
                                    .title{
                                        color: #F37F20;
                                        font-size: 21px;
                                        width: 116px;
                                        text-align: left;
                                        margin-top: 10px;
                                        margin-left:43%;
                                        padding: 10px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 10px 38px 0px 38px;
                                        text-align: left;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                        // mobile step4
                        @media screen and (max-width: 700px){
                            margin-top: 40px;
                            .content-step{
                                height: 305px;
                                width: 100%;
                                background-color: #BAE3F6;
                                padding: 10px;
                                border-radius: 10px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 75%;
                                    height: 100%;
                                    .circle-number{
                                        width: 100px;
                                        height: 100px;
                                        margin-left: -8%;
                                        margin-top: -75px;
                                    }
                                    // title
                                    .title{
                                        font-size: 21px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 50px;
                                        margin-left: 58px;
                                        padding: 10px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 58px 0px 58px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 630
                        @media screen and (max-width: 630px){
                            .content-step{
                                height: 305px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 82%;
                                    // title
                                    .title{
                                        margin-top: 40px;
                                    }
                                }
                            }
                        }
                        // 550
                        @media screen and (max-width: 550px){
                            .content-step{
                                height: 305px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 88%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 500
                        @media screen and (max-width: 500px){
                            .content-step{
                                height: 305px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 95%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 480
                        @media screen and (max-width: 480px){
                            .content-step{
                                height: 285px;
                                .background-step{
                                    width: 100%;
                                    .circle-number{
                                        margin-left: -6%;
                                    }
                                }
                            }
                        }
                        // 430
                        @media screen and (max-width: 430px){
                            .content-step{
                                height: 265px;
                                .background-step{
                                    // title
                                    .title{
                                        font-size: 24px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 30px;
                                        margin-left: 42px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 42px 0px 42px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 400
                        @media screen and (max-width: 400px){
                            .content-step{
                                height: 220px;
                                .background-step{
                                    // title
                                    .circle-number{
                                        width: 95px;
                                        height: 95px;
                                        margin-left: -7%;
                                        margin-top: -85px;
                                    }
                                    .title{
                                        font-size: 24px;
                                        width: 150px;
                                        text-align: left;
                                        margin-top: 20px;
                                        margin-left: 38px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 38px 0px 38px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        // 350
                        @media screen and (max-width: 350px){
                            margin-top: 40px;
                            .content-step{
                                height: 195px;
                                .background-step{
                                    // title
                                    .circle-number{
                                        width: 87px;
                                        height: 87px;
                                        margin-left: -8%;
                                        margin-top: -85px;
                                    }
                                    .title{
                                        font-size: 21px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 10px;
                                        margin-left: 33px;
                                        padding: 5px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 33px 0px 33px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        .onSp {
                            @media screen and (max-width: 700px) {
                                &.right {
                                    top: 0;
                                }
                            }
                        }
                        .arrow {
                            text-align: center;
                            position: relative;
                            right: 20px;
                            @media screen and (max-width: 700px) {
                                @include flexbox;
                                @include justify-content(center);
                                right: unset;
                                margin-top: 10px;
                            }
                        }
                    }
                    .step5 {
                        position: relative;
                        text-align: center;
                        .content-step{
                            position: absolute;
                            top: -60px;
                            right: -225px;
                            z-index: 3;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 210px;
                            width: 100%;
                            margin-left: 0px;
                            margin-right: 0px;
                            .background-step{
                                width: 100%;
                                height: 100%;
                                background-image: url("../img/flow/background/step5.png");
                                background-size: 100%;
                                background-repeat: no-repeat;
                                position: relative;
                                .circle-number{
                                    background-color: #F37F20;
                                    width: 100px;
                                    height: 100px;
                                    border-radius: 50px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-left: 12%;
                                    margin-top: -62px;
                                    position: absolute;
                                    span{
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        font-family: 'M PLUS Rounded 1c', sans-serif;
                                        font-weight: bold;
                                        color: white;
                                    }
                                    span.num-1{
                                        margin-top: 20px;
                                        font-size: 24px;
                                        line-height: 10px;
                                    }
                                    span.num-2{
                                        font-size: 36px;
                                    }
                                }
                                // title
                                .title{
                                    color: #F37F20;
                                    font-size: 23px;
                                    font-weight: 600;
                                    width: 262px;
                                    border-bottom: 1px dashed #EEEEEE;
                                    text-align: left;
                                    margin-top: 28px;
                                    margin-left: 13%;
                                    padding: 15px 0px;
                                    line-height: 30px;
                                }
                                // desc
                                .desc{
                                    margin: 17px 60px 0px 50px;
                                    text-align: left;
                                    font-size: 16px;
                                }
                            }
                        }
                        // tablet step5
                        @media screen and (max-width: 768px){
                            .content-step{
                                top: -95px;
                                right: -175px;
                                height: 210px;
                                .background-step{
                                    .circle-number{
                                        width: 80px;
                                        height: 80px;
                                        margin-left: 12%;
                                        margin-top: -62px;
                                        span.num-1{
                                            font-size: 18px;
                                        }
                                        span.num-2{
                                            font-size: 24px;
                                        }
                                    }
                                    // title
                                    .title{
                                        font-size: 21px;
                                        font-weight: 600;
                                        width: 242px;
                                        margin-top: 15px;
                                        margin-left: 8%;
                                        padding: 10px 0px;
                                        line-height: 30px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 10px 38px 0px 38px;
                                        text-align: left;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                        // mobile step5
                        @media screen and (max-width: 700px){
                            margin-top: 60px;
                            .edit-onSp-blue{
                                position: absolute;
                                z-index: 6;
                                top: 35px; 
                            }
                            .edit-onSp-green{
                                position: absolute;
                                z-index: 6; 
                                top: 0px; 
                            }
                            .content-step{
                                position: relative;
                                top: 0px;
                                right: 0px;
                                z-index: 1;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 300px;
                                width: 100%;
                                margin-top: 40px;
                                margin-left: 0px;
                                margin-right: 0px;
                                background: -webkit-linear-gradient(left, #CFE3A9 50%, #BAE3F6 50%);
                                padding: 10px;
                                border-radius: 10px;
                                .background-step{
                                    margin-top: 85px;
                                    width: 75%;
                                    height: 100%;
                                    .circle-number{
                                        width: 100px;
                                        height: 100px;
                                        margin-left: -8%;
                                        margin-top: -95px;
                                    }
                                    // title
                                    .title{
                                        font-size: 24px;
                                        width: 295px;
                                        text-align: left;
                                        margin-top: 50px;
                                        margin-left: 58px;
                                        padding: 10px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 58px 0px 58px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 630
                        @media screen and (max-width: 630px){
                            .content-step{
                                height: 300px;
                                .background-step{
                                    margin-top: 85px;
                                    width: 82%;
                                    // title
                                    .title{
                                        margin-top: 40px;
                                    }
                                }
                            }
                        }
                        // 550
                        @media screen and (max-width: 550px){
                            .content-step{
                                height: 300px;
                                .background-step{
                                    margin-top: 80px;
                                    width: 88%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 500
                        @media screen and (max-width: 500px){
                            .content-step{
                                height: 300px;
                                .background-step{
                                    margin-top: 80px;
                                    width: 95%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 480
                        @media screen and (max-width: 480px){
                            .content-step{
                                height: 290px;
                                .background-step{
                                    width: 100%;
                                    .circle-number{
                                        margin-left: -6%;
                                    }
                                    .title{
                                        width: 300px;
                                    }
                                }
                            }
                        }
                        // 430
                        @media screen and (max-width: 430px){
                            .content-step{
                                height: 260px;
                                .background-step{
                                    // title
                                    .title{
                                        font-size: 24px;
                                        text-align: left;
                                        margin-top: 30px;
                                        margin-left: 42px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 42px 0px 42px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 400
                        @media screen and (max-width: 400px){
                            .content-step{
                                height: 230px;
                                .background-step{
                                    // title
                                    .circle-number{
                                        width: 95px;
                                        height: 95px;
                                        margin-left: -7%;
                                        margin-top: -105px;
                                    }
                                    .title{
                                        font-size: 21px;
                                        width: 250px;
                                        text-align: left;
                                        margin-top: 20px;
                                        margin-left: 38px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 38px 0px 38px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        // 350
                        @media screen and (max-width: 350px){
                            margin-top: 40px;
                            .content-step{
                                height: 210px;
                                .background-step{
                                    margin-top: 90px;
                                    // title
                                    .circle-number{
                                        width: 87px;
                                        height: 87px;
                                        margin-left: -8%;
                                        margin-top: -85px;
                                    }
                                    .title{
                                        font-size: 21px;
                                        width: 240px;
                                        text-align: left;
                                        margin-top: 20px;
                                        margin-left: 33px;
                                        padding: 5px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 33px 0px 33px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        .arrow {
                            text-align: center;
                            position: relative;
                            right: 20px;
                            @media screen and (max-width: 700px) {
                                @include flexbox;
                                @include justify-content(center);
                                right: unset;
                                margin-top: 10px;
                            }
                        }
                    }
                    .step6 {
                        text-align: center;
                        position: relative;
                        .content-step{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 231px;
                            width: 100%;
                            margin-left: 0px;
                            margin-right: 0px;
                            .background-step{
                                width: 100%;
                                height: 100%;
                                background-image: url("../img/flow/background/step6.png");
                                background-size: 100%;
                                background-repeat: no-repeat;
                                position: relative;
                                .circle-number{
                                    background-color: #F37F20;
                                    width: 100px;
                                    height: 100px;
                                    border-radius: 50px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-left: 55%;
                                    margin-top: -10px;
                                    position: absolute;
                                    span{
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        font-family: 'M PLUS Rounded 1c', sans-serif;
                                        font-weight: bold;
                                        color: white;
                                    }
                                    span.num-1{
                                        margin-top: 20px;
                                        font-size: 24px;
                                        line-height: 10px;
                                    }
                                    span.num-2{
                                        font-size: 36px;
                                    }
                                }
                                // title
                                .title{
                                    color: #F37F20;
                                    font-size: 23px;
                                    font-weight: 600;
                                    width: 165px;
                                    border-bottom: 1px dashed #EEEEEE;
                                    text-align: left;
                                    margin-top: 30px;
                                    margin-left: 50px;
                                    padding: 15px 0px;
                                }
                                // desc
                                .desc{
                                    margin: 17px 50px 0px 50px;
                                    text-align: left;
                                    font-size: 16px;
                                }
                            }
                        }
                        // tablet step6
                        @media screen and (max-width: 768px){
                            .content-step{
                                height: 231px;
                                .background-step{
                                    .circle-number{
                                        width: 80px;
                                        height: 80px;
                                        margin-left: 55%;
                                        margin-top: -10px;
                                        span.num-1{
                                            font-size: 18px;
                                        }
                                        span.num-2{
                                            font-size: 24px;
                                        }
                                    }
                                    // title
                                    .title{
                                        color: #F37F20;
                                        font-size: 21px;
                                        width: 100px;
                                        text-align: left;
                                        margin-top: 20px;
                                        margin-left: 50px;
                                        padding: 10px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 10px 38px 0px 38px;
                                        text-align: left;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                        // mobile step6
                        @media screen and (max-width: 700px){
                            margin-top: 40px;
                            .content-step{
                                height: 325px;
                                width: 100%;
                                background-color: #CFE3A9;
                                padding: 10px;
                                border-radius: 10px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 75%;
                                    height: 100%;
                                    .circle-number{
                                        width: 100px;
                                        height: 100px;
                                        margin-left: -8%;
                                        margin-top: -75px;
                                    }
                                    // title
                                    .title{
                                        font-size: 24px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 50px;
                                        margin-left: 58px;
                                        padding: 10px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 58px 0px 58px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 630
                        @media screen and (max-width: 630px){
                            .content-step{
                                height: 325px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 82%;
                                    // title
                                    .title{
                                        margin-top: 40px;
                                    }
                                }
                            }
                        }
                        // 550
                        @media screen and (max-width: 550px){
                            .content-step{
                                height: 325px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 88%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 500
                        @media screen and (max-width: 500px){
                            .content-step{
                                height: 325px;
                                .background-step{
                                    margin-top: 40px;
                                    width: 95%;
                                    // title
                                    .title{
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                        // 480
                        @media screen and (max-width: 480px){
                            .content-step{
                                height: 325px;
                                .background-step{
                                    width: 100%;
                                    .circle-number{
                                        margin-left: -6%;
                                    }
                                }
                            }
                        }
                        // 430
                        @media screen and (max-width: 430px){
                            .content-step{
                                height: 325px;
                                .background-step{
                                    // title
                                    .title{
                                        font-size: 24px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 30px;
                                        margin-left: 42px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 42px 0px 42px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 480
                        @media screen and (max-width: 480px){
                            .content-step{
                                height: 300px;
                                .background-step{
                                    width: 100%;
                                    .circle-number{
                                        margin-left: -6%;
                                    }
                                }
                            }
                        }
                        // 430
                        @media screen and (max-width: 430px){
                            .content-step{
                                height: 275px;
                                .background-step{
                                    // title
                                    .title{
                                        font-size: 24px;
                                        text-align: left;
                                        margin-top: 30px;
                                        margin-left: 42px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 42px 0px 42px;
                                        text-align: left;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                        // 400
                        @media screen and (max-width: 400px){
                            .content-step{
                                height: 225px;
                                .background-step{
                                    // title
                                    .circle-number{
                                        width: 95px;
                                        height: 95px;
                                        margin-left: -7%;
                                        margin-top: -85px;
                                    }
                                    .title{
                                        font-size: 21px;
                                        width: 250px;
                                        text-align: left;
                                        margin-top: 20px;
                                        margin-left: 38px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 38px 0px 38px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        // 350
                        @media screen and (max-width: 350px){
                            margin-top: 40px;
                            .content-step{
                                height: 210px;
                                .background-step{
                                    // title
                                    .circle-number{
                                        width: 87px;
                                        height: 87px;
                                        margin-left: -8%;
                                        margin-top: -85px;
                                    }
                                    .title{
                                        font-size: 21px;
                                        width: 165px;
                                        text-align: left;
                                        margin-top: 10px;
                                        margin-left: 33px;
                                        padding: 5px 0px;
                                    }
                                    // desc
                                    .desc{
                                        margin: 5px 33px 0px 33px;
                                        text-align: left;
                                        font-size: 13px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                        .arrow {
                            margin-top: 45px;
                            text-align: center;
                            margin-bottom: 10px;
                            @media screen and (max-width:768px){
                                margin-top: -10px;
                            }
                        }
                    }
                    .icon {
                        text-align: center;
                        padding: 235px 0 185px;
                        @media screen and (max-width: 768px) {
                            padding: 175px 0 185px;
                        }
                        &.one {
                            padding: 260px 0 215px;
                            @media screen and (max-width: 768px) {
                                padding: 200px 0 200px;
                            }
                        }
                        &.two {
                            padding: 125px 0 0;
                        }
                    }
                }
            }
        }
    }
    /*COMING SOON*/
    &.comingsoon {
        padding: 50px 0;
        .inner {
            text-align: center;
            h2 {
                font-size: 100px;
                font-weight: 500;
                color: $title;
            }
            p {
                line-height: 2;
                font-size: 20px;
            }
        }
    }
    // FACILITY
    &.facility{
        font-family: $fontPrimary;
        margin-bottom: 100px;
        .text-title{
            margin-top: 17pt; 
            p{
                @include flexbox();
                @include justify-content(center);
                color: #624417;
                font-size: 138%;
                line-height: 35px;
                text-align: center;
                @media screen and (max-width:600px){
                    font-size: 113%;
                }
                @media screen and (max-width:480px){
                    font-size: 100%;
                }
            }
        }
        .map-facility{
            max-width: 498px;
            max-height: 488px;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
        }
        .card{
            &.row{
                margin: 20px 0 0 0;
            }
            .col-lg-3{
                padding: 13px;
            }
            .col-md-4{
                padding: 13px;
            }
            .col-sm-6{
                padding: 13px;
            }
            .items{
                border-radius: 10px;
                display: block;
                .top{
                    position: relative;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                .down{
                    background-color: #FFFFFF;
                    width: 100%;
                    height: 60px;
                    border-radius: 0 0 10px 10px;
                    @include flexbox();
                    @include flex-direction(column);
                    @include justify-content(center);
                    @include align-items(center);
                    position: relative;
                    p{
                        flex-direction: column;
                        font-size: 100%;
                        color: #5E4417;
                    }
                    span.triangle-top{
                        position: absolute;
                        left: 0;
                        top: 0;
                        // 
                        width: 0;
                        height: 0;
                        border-top: 24px solid transparent;
                        border-right: 24px solid #FFFFFF;
                    }
                    span.triangle-down{
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        // 
                        width: 0;
                        height: 0;
                        border-bottom: 24px solid transparent;
                        border-left: 24px solid #FFFFFF;
                    }
                }
                &.card-1{
                    background-color: #F4C9E1;
                    border: 4px solid #F4C9E1;
                    span.triangle-top{
                        background-color: #F4C9E1;
                    }
                    span.triangle-down{
                        background-color: #F4C9E1;
                    }
                }
                &.card-2{
                    background-color: #A0EAA9;
                    border: 4px solid #A0EAA9;
                    span.triangle-top{
                        background-color: #A0EAA9;
                    }
                    span.triangle-down{
                        background-color: #A0EAA9;
                    }      
                }
                &.card-3{
                    background-color: #FFE579;
                    border: 4px solid #FFE579;
                    span.triangle-top{
                        background-color: #FFE579;
                    }
                    span.triangle-down{
                        background-color: #FFE579;
                    }  
                }
                &.card-4{
                    background-color: #CDBED8;
                    border: 4px solid #CDBED8;
                    span.triangle-top{
                        background-color: #CDBED8;
                    }
                    span.triangle-down{
                        background-color: #CDBED8;
                    }  
                }
                &.card-5{
                    background-color: #A2ECF4;
                    border: 4px solid #A2ECF4;
                    span.triangle-top{
                        background-color: #A2ECF4;
                    }
                    span.triangle-down{
                        background-color: #A2ECF4;
                    }  
                }
                &.card-6{
                    background-color: #FFC888;
                    border: 4px solid #FFC888;
                    span.triangle-top{
                        background-color: #FFC888;
                    }
                    span.triangle-down{
                        background-color: #FFC888;
                    } 
                }
                &.card-7{
                    background-color: #D9CCB8;
                    border: 4px solid #D9CCB8;
                    span.triangle-top{
                        background-color: #D9CCB8;
                    }
                    span.triangle-down{
                        background-color: #D9CCB8;
                    } 
                }
                &.card-8{
                    background-color: #FFB1BD;
                    border: 4px solid #FFB1BD;
                    span.triangle-top{
                        background-color: #FFB1BD;
                    }
                    span.triangle-down{
                        background-color: #FFB1BD;
                    } 
                }
            }
            
        }
    }
    // DETAIL FACILITY
    &.detail-facility{
        .inner{
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(center);
            @include align-items(center);
            // all page detail-facility
            // background-color
            .background_hijau {
                background-color: #A0EAA9 !important;
            }
            .background_kuning {
                background-color: #FFE579 !important;
            }
            .background_ungu {
                background-color: #CDBED8 !important;
            }
            .background_biru {
                background-color: #A2ECF4 !important;
            }
            .background_orange {
                background-color: #FFC888 !important;
            }
            .background_abu {
                background-color: #D9CCB8 !important;
            }
            .background_pink {
                background-color: #FFB1BD !important;
            }
            // border-color
            .border_hijau {
                border-color: #A0EAA9 !important;
            }
            .border_kuning {
                border-color: #FFE579 !important;
            }
            .border_ungu {
                border-color: #CDBED8 !important;
            }
            .border_biru {
                border-color: #A2ECF4 !important;
            }
            .border_orange {
                border-color: #FFC888 !important;
            }
            .border_abu {
                border-color: #D9CCB8 !important;
            }
            .border_pink {
                border-color: #FFB1BD !important;
            }
            // table color
            .table_hijau{
                th{
                    background-color: #A0EAA9 !important;
                }
                td{
                    background-color: #d3ffdb !important;
                }
            }
            .table_kuning{
                th{
                    background-color: #FFE579 !important;
                }
                td{
                    background-color: #ffffaa !important;
                }
            }
            .table_ungu{
                th{
                    background-color: #CDBED8 !important;
                }
                td{
                    background-color: #e9d8f2 !important;
                }
            }
            .table_biru{
                th{
                    background-color: #A2ECF4 !important;
                }
                td{
                    background-color: rgba(184, 237, 243, 0.801) !important;
                }
            }
            .table_orange{
                th{
                    background-color: #FFC888 !important;
                }
                td{
                    background-color: rgb(255, 223, 185) !important;
                }
            }
            .table_abu{
                th{
                    background-color: #D9CCB8 !important;
                }
                td{
                    background-color: rgba(235, 226, 212, 0.959) !important;
                }
            }
            .table_pink{
                th{
                    background-color: #FFB1BD !important;
                }
                td{
                    background-color: #ffe3ef !important;
                }
            }
            // circle title 
            .circle_hijau {
                background-color: #A0EAA9 !important;
                box-shadow:0px 0px 20px 2px #A0EAA9 !important;
            }
            .circle_kuning {
                background-color: #FFE579 !important;
                box-shadow:0px 0px 20px 2px #FFE579 !important;
            }
            .circle_ungu {
                background-color: #CDBED8 !important;
                box-shadow:0px 0px 20px 2px #CDBED8 !important;
            }
            .circle_biru {
                background-color: #A2ECF4 !important;
                box-shadow:0px 0px 20px 2px #A2ECF4 !important;
            }
            .circle_orange {
                background-color: #FFC888 !important;
                box-shadow:0px 0px 20px 2px #FFC888 !important;
            }
            .circle_abu {
                background-color: #D9CCB8 !important;
                box-shadow:0px 0px 20px 2px #D9CCB8 !important;
            }
            .circle_pink {
                background-color: #FFB1BD !important;
                box-shadow:0px 0px 20px 2px #FFB1BD !important;
            }


            // 1
            .firstRow{
                margin-top: 58px;
                .name-card{
                    @include flexbox();
                    @include flex-direction(column);
                    @include justify-content(center);
                    @include align-items(flex-start);
                    width: 100%;
                    max-width: 980px;
                    height: 120px;
                    border: 5px solid #F4C9E1;
                    background-color: #FFFFFF;
                    border-radius: 10px 10px 0 0;
                    position: relative;
                    span.triangle-top{
                        position: absolute;
                        left: 0;
                        top: 0;
                        // 
                        width: 0;
                        height: 0;
                        border-top: 60px solid transparent;
                        border-right: 60px solid #FFFFFF;
                        background-color: #F4C9E1;
                    }
                    span.triangle-down{
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        // 
                        width: 0;
                        height: 0;
                        border-bottom: 60px solid transparent;
                        border-left: 60px solid #FFFFFF;
                        background-color: #F4C9E1;
                    }
                    p{
                        margin-left: 60px;
                        @media screen and (max-width:480px){
                            margin-left: 40px;
                        }
                    }
                    .title{
                        font-size: 206%;
                        color: #624417;
                        font-family: $fontMaru;
                        margin-top: -10px;
                        padding-right: 10px;
                        z-index: 80;
                        word-break: keep-all;
                        @media screen and (max-width:480px){
                            font-size: 150%;
                            margin-left: 40px;
                        }
                        @media screen and (max-width:390px){
                            font-size: 130%;
                            margin-left: 40px;
                        }
                        @media screen and (max-width:340px){
                            font-size: 105%;
                            margin-left: 40px;
                        }
                    }
                    .desc{
                        font-size: 120%;
                        color: black;
                        margin-top: -3px;
                        z-index: 80;
                        @media screen and (max-width:480px){
                            font-size: 100%;
                        }
                        @media screen and (max-width:390px){
                            font-size: 88%;
                        }
                    }
                    &.long-text{
                        .title{
                            @media screen and (max-width:700px){
                                line-height: 35px;
                                font-size: 170%;
                            }
                            @media screen and (max-width:676px){
                                line-height: 30px;
                            }
                            @media screen and (max-width:568px){
                                font-size: 150%;
                            }
                            @media screen and (max-width:500px){
                                font-size: 130%;
                            }
                            @media screen and (max-width:430px){
                                font-size: 120%;
                            }
                        }
                        .desc{
                            margin-top: 5px;
                            margin-bottom: -10px;
                            @media screen and (max-width:430px){
                                font-size: 88%;
                                margin-top: 2.5px;
                                margin-bottom: -2.5px;
                            }
                        }
                    }
                }
                .image-card{
                    background-color: #FFFFFF;
                    width:100%;
                    max-width: 980px;
                    @include flexbox();
                    @include flex-direction(column);
                    @include justify-content(center);
                    @include align-items(center);
                    img{
                        padding: 20px 20px 0px 20px;
                        width: 100%;
                        height: auto;
                    }
                    .desc{
                        padding:20px;
                        width: 100%;
                        @include flexbox();
                        @include flex-direction(column);
                        @include justify-content(flex-start);
                        @include align-items(flex-start);
                        p{
                            font-size: 100%;
                            line-height: 30px;
                        }
                    }
                }
            }
            // 2
            .secondRow{
                margin-top: 80px;
                width: 100%;
                max-width: 980px;
                display: block !important;
                .title{
                    @include flexbox();
                    @include justify-content(center);
                    @include align-items(center);
                    p{
                        font-size: 206%;
                        color: #624417;
                        font-family: $fontMaru;
                    }
                }
                .carousel-card{
                    margin-top: 27px;
                    .owl-item:first-child,
                    .owl-item:nth-child(10) {
                        display: none;
                    }
                    .item{
                        background-color: transparent;
                        a {
                            display: block;
                            .img-card{
                                width: 100%;
                                height: 180px;
                                @include border-radius(10px 10px 0 0);
                                @media screen and (max-width: 599px) {
                                    height: 50vw;
                                }
                                div {
                                    width: 100%;
                                    height: 100%;
                                    @include border-radius(10px 10px 0 0);
                                    background-image: url(../img/detail-facility/img1-carousel.png);
                                }
                            }
                            .title-card{
                                width: 100%;
                                height: 40px;
                                background-color: #FFFFFF;
                                border-radius: 0 0 10px 10px;
                                padding: 0 15px;
                                @include flexbox();
                                @include justify-content(center);
                                @include align-items(center);
                                p{
                                    font-size: 88%;
                                    color: #624417;
                                    overflow: hidden;
                                    max-height: 22px;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                        
                    }
                    .owl-carousel {
                        display: block;
                        position: relative;
                        .owl-prev{
                            font-size: 50px;
                            color: #0672B2;
                            font-weight: 24px;
                            position: absolute;
                            z-index: 5;
                            top: 65px;
                            left: -50px;
                        }
                        .owl-prev:hover{
                            background-color: transparent;
                            color: rgb(124, 162, 184);
                        }
                        .owl-prev:visited{
                            background-color: transparent;
                            border: transparent;
                        }
                        .owl-next{
                            font-size: 50px;
                            color: #0672B2;
                            font-weight: 24px;
                            position: absolute;
                            z-index:5;
                            top: 65px;
                            right: -50px;
                        }
                        .owl-next:hover{
                            background-color: transparent;
                            color: rgb(124, 162, 184);
                        }
                    }
                    @media screen and (max-width:1000px){
                        .owl-nav{
                            display: none !important;
                        }
                        .owl-dots{
                            margin-top: 30px;
                            span{
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                    @media screen and (max-width:480px){
                        .owl-dots{
                            margin-top: 30px;
                            span{
                                width: 10px;
                                height: 10px;
                            }
                        }
                    }
                }
            }
            // 3
            .thirdRow{
                margin-top: 80px;
                width: 100%;
                max-width: 980px;
                .title{
                    @include flexbox();
                    @include justify-content(center);
                    @include align-items(center);
                    p{
                        font-size: 206%;
                        color: #624417;
                        font-family: $fontMaru;
                    }
                }
                .list-desc{
                    margin-top: 19px;
                    ul.Unordered-grup{
                        li.list-grup{
                            padding: 8px 0;
                            list-style-type: none;
                            &.borderDashed{
                                border-bottom: 1px dashed #0672B2;
                            }
                            ul{
                                margin-left: 20px;
                                li{
                                    list-style-type: disc;
                                    color: #0672B2;
                                    p{
                                        padding-left: 2px;
                                        font-size: 100%;
                                        color: #212121;
                                        line-height: 30px;
                                        text-align: justify;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // 4
            .fourthRow{
                margin-top: 65px;
                width: 100%;
                max-width: 980px;
                display: block !important;
                .nav .nav-tabs{
                    width: 100%;
                }
                ul.nav{
                    border-bottom: 1px solid #FFFFFF;
                    @include flexbox();
                    @include flex-direction(row);
                    @include justify-content(flex-start);
                    li {
                        margin-right: 20px;
                        width: 100%;
                        max-width: 200px;
                        height: 58px;
                        background-color: #BAE3F6;
                        border-radius: 10px 10px 0 0;
                        border: transparent;
                        a{
                          width: 100%;
                          height: 100%;  
                          background-color: transparent;
                          border-radius: 10px 10px 0 0;
                          @include flexbox();
                          @include justify-content(center);
                          @include align-items(center);
                          color: #FFFFFF;
                          border: transparent;
                          @media screen and (max-width:325px){
                              font-size: 80%;
                          }
                        }
                        &.active{
                            color: #FFFFFF;
                            background-color: #53B8E9;
                            border: transparent;
                        }   
                    }
                    @media screen and (max-width:550px){ 
                        @include flexbox();
                        @include flex-direction(row);
                        @include justify-content(space-between);
                        width: 100%;
                        // margin-left: -5px;
                        // margin-right: -5px;
                        li{
                            margin-right: 10px;
                            width: 100%;
                        }
                        .edit{
                            margin-right: 0px;
                        }
                    }
                }

                .tab-content{
                    background-color: #FFFFFF;
                    width: 100%;
                    height: 100%;
                    border: transparent;
                    padding: 30px;
                    border-radius: 0px 10px 10px 10px;
                    @media screen and (max-width:550px){ 
                        border-radius: 0px 0px 10px 10px;
                        padding: 20px;
                    }
                    // title-content
                    .titleContent{
                        .text-title{
                            @include flexbox();
                            @include justify-content(flex-start);
                            @include align-items(center);
                            width: 100%;
                            height: 40px;
                            background-color: #F4C9E1;
                            border-radius: 10px;
                            p{
                                font-size: 125%;
                                color: #212121;
                                padding: 20px;
                            }
                        }
                        .text-desc{
                            margin-top: 20px;
                            line-height: 30px;
                        }
                        //title2
                        &.editPlus{
                            margin-top: 50px;
                            .text-desc{
                                p{
                                    letter-spacing: -0.1px;
                                    margin: 3px 0 0 0;
                                    line-height: 35px;
                                }
                                p.sub-title{
                                    margin-top: 0px;
                                    font-size: 113%;
                                    color: #0672B2;
                                }
                            }
                        }
                    }
                    //img-content
                    .img-content{
                        margin-top: 30px;
                        img{
                            width: 100%;
                            height: auto;
                        }
                    }
                    // list-content
                    ul{
                        li{
                            list-style-type: disc;
                            color: #0672B2;
                            margin-left: 20px;
                            padding: 3px 0;
                            p{
                                font-size: 100%;
                                color: #212121;
                                letter-spacing: 1.5;
                                padding-left: 2px;
                            }
                            &.edit-list{
                                list-style-type: none;
                                margin-left: -5px;
                                margin-top: 24px;
                                p{
                                    padding-left: 0px;
                                    &:before{
                                        content: "※";
                                        font-size: 88%;
                                        color: #E1242B;
                                    }
                                }
                            }
                        }
                    }
                    // img-card
                    .img-card{
                        margin-top: 50px;
                        .title{
                            @include flexbox();
                            @include justify-content(center);
                            @include align-items(center);
                            p{
                                font-size: 113%;
                                color: #624417;
                                font-family: $fontMaru;
                            }
                        }
                        .row{
                            margin-top: 5px;
                            margin-left: -10px;
                            margin-right: -10px;
                            img{
                                width: 100%;
                                height: 167.44px;
                                object-fit: cover;
                                border-radius: 10px;
                                @media screen and (max-width:480px) {
                                    height: 140px;
                                }
                                @media screen and (max-width:380px) {
                                    height: 110px;
                                }
                            }
                            @media screen and (max-width:767px) {
                                margin-left: -5px;
                                margin-right: -5px;
                                height: 80%; 
                            }
                        }
                        .col-md-3{
                            padding: 10px;
                        }
                        .col-xs-6{
                            padding: 5px;
                        }
                    }
                    // alert title
                    .alert-title{
                        background-color: #EEEEEE;
                        border-radius: 10px;
                        p{
                            line-height: 35px;
                            color: #000000;
                            font-size: 100%;
                            padding: 20px;
                        }
                    }
                    // img-tabTwo
                    .img-tabTwo{
                        margin-top: 26px;
                        @include flexbox();
                        @include justify-content(center);
                        @include align-items(center);
                        img{
                            width: 100%;
                            max-width: 400px;
                            height: auto;
                        }
                    }
                    // list content tabTwo
                    .list-content-tabTwo{
                        margin-top: 45px;
                        @include flexbox();
                        @include justify-content(center);
                        @include align-items(center);
                        .row{
                            margin-left: -10px;
                            margin-right: -10px;
                        }
                        .col-md-3, .col-sm-4{
                            padding: 12px 20px 12px 20px;
                            @include flexbox();
                            @include flex-direction(column);
                            @include justify-content(center);
                            @include align-items(center);
                        }
                        .circle-list{
                            width: 200px;
                            height: 200px;
                            background-color: #F4C9DF;
                            border-radius: 50%;
                            @include flexbox();
                            @include flex-direction(column);
                            @include justify-content(center);
                            @include align-items(center);
                            box-shadow:0px 0px 20px 2px #F4C9DF; 
                            border: transparent;
                            padding: 20px;
                            box-sizing: border-box;
                            p{
                                @include flexbox();
                                @include flex-direction(column);
                                @include justify-content(center);
                                font-size: 175%;
                                color: #FFFFFF;
                                text-align: center;
                                line-height: 40px;
                                @media screen and (max-width:790px){
                                    font-size: 160%;
                                    line-height: 35px;
                                }
                                @media screen and (max-width:580px){
                                    font-size: 150%;
                                    line-height: 30px;
                                }
                                @media screen and (max-width:480px){
                                    font-size: 113%;
                                    line-height: 27px;
                                }
                                @media screen and (max-width:340px){
                                    font-size: 100%;
                                }
                            }
                            @media screen and (max-width:768px){
                                width: 190px;
                                height: 190px;
                            }
                            @media screen and (max-width:550px){
                                width: 180px;
                                height: 180px;
                            }
                            @media screen and (max-width:470px){
                                width: 160px;
                                height: 160px;
                            }
                            @media screen and (max-width:420px){
                                width: 140px;
                                height: 140px;
                            }
                            @media screen and (max-width:380px){
                                width: 130px;
                                height: 130px;
                            }
                            @media screen and (max-width:340px){
                                width: 110px;
                                height: 110px;
                            }
                        }
                    }
                    // card-desc1
                    .card-desc1{
                        margin-top: 20px;
                        @include flexbox();
                        @include flex-direction(row);
                        @include justify-content(space-around);
                        .left{
                            padding-right: 10px;
                            img{
                                // border: 1px solid #EEEEEE;
                                border-radius: 10px;
                                padding: 5px 0;
                                width: 100%;
                                width: 400px;
                                height: auto;
                            }
                            @media screen and (max-width:978px){
                                flex: 1;
                            }
                        }
                        .right{
                            flex:1;
                            padding-left: 10px;
                            p{
                                line-height: 34px;
                                text-align: justify;
                                letter-spacing: 0px;
                            }
                            .title{
                                font-size: 113%;
                                color: #0672B2;
                            }
                            .desc{
                                font-size: 100%;
                                color: #212121;
                            }
                        }
                        @media screen and (max-width:768px){
                            @include flexbox();
                            @include flex-direction(column);
                            @include justify-content(space-around);
                            .left{
                                width: 100%;
                                img{
                                    width: 100%;
                                }
                            }
                            .right{
                                margin-top: 20px;
                            }
                        }
                    }
                    // card-desc2
                    .card-desc2{
                        margin-top: 22px;
                        @include flexbox();
                        @include flex-direction(row);
                        @include justify-content(space-around);
                        background-color: #EEEEEE;
                        border-radius: 10px;

                        .left{
                            flex:1;
                            padding: 20px 10px 20px 20px;
                            p{
                                line-height: 35px;
                            }
                            .title{
                                font-size: 113%;
                                color: #0672B2;
                            }
                        }
                        .right{
                            padding: 20px 20px 20px 10px;
                            img{
                               width: 400px; 
                            }
                            @media screen and (max-width:978px){
                                flex: 1;
                            }
                        }
                        @media screen and (max-width:768px){
                            @include flexbox();
                            @include flex-direction(column-reverse);
                            @include justify-content(space-around);
                            .left{
                                padding: 10px 20px 20px 20px;
                            }
                            .right{
                                width: 100%;
                                padding: 20px 20px 10px 20px;
                                img{
                                   width: 100%; 
                                }
                            }
                        }
                    }
                    // descContent
                    .descContent{
                        .text-title{
                            color: #0672B2; 
                            font-size: 113%;
                        }
                        .text-desc{
                            margin-top: 5px;
                            font-size: 100%;
                            line-height: 35px;
                        }
                    }
                    // card-desc3
                    .card-desc3{
                        margin-top: 20px;
                        @include flexbox();
                        @include flex-direction(row);
                        @include justify-content(space-around);
                        border-radius: 10px;               
                        .left{
                            flex:1;
                            margin-right: 15px;
                            p{
                                line-height: 35px;
                            }
                            .title{
                                font-size: 113%;
                                color: #0672B2;
                            }
                        }
                        .right{
                            margin-left: 15px;
                            img{
                                width: 100%;
                                max-width: 400px;
                                height: auto; 
                            }
                            @media screen and (max-width:978px){
                                flex: 1;
                            }
                        }
                        @media screen and (max-width:678px){
                            @include flexbox();
                            @include flex-direction(column-reverse);
                            @include justify-content(space-around);
                            .left{
                                margin-right: 0px;
                                margin-top: 20px;
                            }
                            .right{
                                margin-left: 0px;
                                img{
                                    width: 100%;
                                    max-width: 100%;
                                    height: auto; 
                                }
                            }
                        }
                    }
                    // 
                    .list-before{
                        margin-left: -5px;
                        margin-top: 10px;
                        p{
                            padding-left: 0px;
                            &:before{
                                content: "※";
                                font-size: 88%;
                                color: #E1242B;
                            }
                        }
                    }
                    // table-tabThree
                    .table-tabThree{
                        margin-top: 15px;
                        table{
                            font-size: 100%;
                            color: #212121;
                            width: 100%;
                            tbody{
                                tr{
                                    line-height: 30px;
                                    .edit-radius1{
                                        border-radius: 10px 0 0 0;   
                                    }
                                    .edit-radius2{
                                        border-radius: 0 10px 0 0;   
                                    }
                                    .edit-radius3{
                                        border-radius: 0 0 0 10px;   
                                    }
                                    .edit-radius4{
                                        border-radius: 0 0 10px 0;   
                                    }
                                    @media screen and (max-width:768px){
                                        .edit-radius1, .edit-radius2, .edit-radius3, .edit-radius4{
                                            border-radius: 0 0 0 0;   
                                        }
                                        .radius-top-sp{
                                            border-radius: 10px 10px 0 0;   
                                        }
                                        .radius-down-sp{
                                            border-radius: 0 0 10px 10px;   
                                        }
                                    }
                                    th{
                                        width: 260px;
                                        background-color: #F4C9E1;
                                        border: 1px dashed #FFFFFF;
                                        padding: 15px 20px;
                                    }
                                    td{
                                        padding: 15px 25px;
                                        border: 1px dashed #FFFFFF;
                                        background-color: #FBE9F3;
                                    }
                                    @media screen and (max-width:678px){
                                        @include flexbox();
                                        @include flex-direction(column);
                                        @include justify-content(center);
                                        th{
                                            @include flexbox();
                                            @include flex-direction(row);
                                            @include justify-content(center);
                                            width: 100%;
                                            border: none;
                                            text-align: center;
                                        }
                                        td{
                                            @include flexbox();
                                            @include flex-direction(column);
                                            @include justify-content(center);
                                            @include align-items(center);
                                            width: 100%;
                                            border: none;
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                            @media screen and (max-width:768px){
                                border: none;
                            }
                        }
                    }
                    // list-tabThree
                    .list-tabThree{
                        margin-top: 30px;
                        ul{
                            li{
                                padding: 5px 0;
                                list-style-type: none;
                                margin-left: 30px;
                                p:before{
                                    content: "※";
                                    margin-left: -30px;
                                    margin-right: 10px;
                                    color: #E1242B;
                                }
                            }
                            
                        }
                    }
                    //
                }
            }
            // 5
            .fifthRow{
                width: 100%;
                margin-top: 80px;
                margin-bottom: 80px;
                .sitemap{
                    .text-title{
                        font-size: 206%;
                        color: #624417;
                        font-family: $fontMaru;
                        @include flexbox();
                        @include flex-direction(column);
                        @include justify-content(center);
                        @include align-items(center);
                    }
                    iframe{
                        margin-top: 20px;
                        width: 122%;
                        height: 400px;
                        margin-left: -11%;
                        margin-right: -11%;
                        @media screen and (max-width:1280px){
                            width: 110%;
                            margin-left: -5%;
                            margin-right: -5%;
                        }
                    }
                }
            }

            
        }
    }
    // CHARACTER
    &.character{
        .inner{
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(center);
            @include align-items(center);
            margin-bottom: 120px;
            // margin
            @media screen and (max-width:950px){
                margin-bottom: 60px;                
            }
        }
        // template card section
        .card-section{
            @include flexbox();
            @include flex-direction(row);
            @include justify-content(space-around);
            margin-left: -25px;
            margin-right: -25px;
            .left{
                flex:1;
                padding: 0px 25px 0px 25px;
                // margin-top: -10px;
                .number{
                    font-family: $fontMaru;
                    font-size: 262%;
                    font-weight: bold;
                    line-height: 55px;
                }
                .title{
                    width: 100%;
                    line-height: 45px;
                    padding: 0;
                    p{
                        color: $title;
                        font-family: $fontMaru;
                        font-size: 206%;
                        @media screen and (max-width:480px){
                            font-size: 175%;
                        }
                    }
                    .hr-title{
                        // width: 100%;
                        margin-top: 20px;
                        width: 100%;
                        max-width: 260px ;
                        height: 4px;
                        background-color: #FFB1BD;
                        border-radius: 25px;
                    }
                }
                .desc{
                    margin-top: 20px;
                    p{
                        font-size: 100%;
                        color: #000000;
                        line-height: 35px;
                    }
                    .style-li-desc::before{
                        content: "※";
                        margin-left: -20px;
                    }
                    .style-li-desc{
                        margin-left: 20px;
                        margin-top: 15px;
                        line-height: 30px;
                    }            
                }
                @media screen and (max-width:1280px){
                    margin-top: -15px;
                    flex: 1;
                }
            }
            .right{
                padding: 0px 25px 0px 25px;
                img{
                   width: 565px; 
                }
                @media screen and (max-width:1280px){
                    flex: 1;
                }
                @media screen and (max-width:1180px){
                    margin-top: 6%;
                }
            }
            @media screen and (max-width:950px){
                @include flexbox();
                @include flex-direction(column-reverse);
                @include justify-content(space-around);
                .left{
                    margin-top: 20px;
                }
                .right{
                    padding: 0px 25px 0px 25px;
                    img{
                       width: 100%; 
                    }
                }
            }
        }
        // 1 
        .firstRow{
            margin-top: 60px;
            .edit-card-section{
                .left{
                    .number{
                        color: #FFB1BD;
                    }
                    .title{
                        .hr-title{
                            max-width: 260px ;
                            background-color: #FFB1BD;
                        }
                    }
                }
            }
            // 
            @media screen and (max-width:950px){
                @include flexbox();
                @include flex-direction(column-reverse);
                @include justify-content(space-around);
            }
            // margin-section
            @media screen and (max-width:950px){
                margin-top: 30px;                
            }
        }
        // 2
        .secondRow{
            margin-top: 120px;
            .edit-card-section{
                @include flexbox();
                @include flex-direction(row-reverse);
                @include justify-content(space-around);
                .left{
                    .number{
                        color: #CDBED8;
                    }
                    .title{
                        .hr-title{
                            max-width: 295px ;
                            background-color: #CDBED8;
                        }
                    }
                }
                // 
                @media screen and (max-width:950px){
                    @include flexbox();
                    @include flex-direction(column-reverse);
                    @include justify-content(space-around);
                }
            }
            // margin-section
            @media screen and (max-width:950px){
                margin-top: 30px;                
            }
        }
        // 3
        .thirdRow{
            margin-top: 120px;
            .edit-card-section{
                @include flexbox();
                @include flex-direction(row);
                @include justify-content(space-around);
                .left{
                    .number{
                        color: #A2ECF4;
                    }
                    .title{
                        .hr-title{
                            max-width: 350px ;
                            background-color: #A2ECF4;
                        }
                    }
                }
                // 
                @media screen and (max-width:950px){
                    @include flexbox();
                    @include flex-direction(column-reverse);
                    @include justify-content(space-around);
                }
            }
            // margin-section
            @media screen and (max-width:950px){
                margin-top: 30px;                
            }
        }
        // 4
        .fourthRow{
            margin-top: 120px;
            .edit-card-section{
                @include flexbox();
                @include flex-direction(row-reverse);
                @include justify-content(space-around);
                .left{
                    .number{
                        color: #A0EAA9;
                    }
                    .title{
                        .hr-title{
                            max-width: 250px ;
                            background-color: #A0EAA9;
                        }
                    }
                }
                // 
                @media screen and (max-width:950px){
                    @include flexbox();
                    @include flex-direction(column-reverse);
                    @include justify-content(space-around);
                }
            }
            // margin-section
            @media screen and (max-width:950px){
                margin-top: 30px;                
            }
        }
        // 5
        .fifthRow{
            margin-top: 120px;
            .edit-card-section{
                @include flexbox();
                @include flex-direction(row);
                @include justify-content(space-around);
                .left{
                    .number{
                        color: #FFE579;
                    }
                    .title{
                        .hr-title{
                            max-width: 440px ;
                            background-color: #FFE579;
                        }
                    }
                }
                // 
                @media screen and (max-width:950px){
                    @include flexbox();
                    @include flex-direction(column-reverse);
                    @include justify-content(space-around);
                }
            }
            // margin-section
            @media screen and (max-width:950px){
                margin-top: 30px;                
            }
        }
        // 6
        .sixthRow{
            margin-top: 120px;
            .edit-card-section{
                @include flexbox();
                @include flex-direction(row-reverse);
                @include justify-content(space-around);
                .left{
                    .number{
                        color: #FFC888;
                    }
                    .title{
                        .hr-title{
                            max-width: 385px ;
                            background-color: #FFC888;
                        }
                    }
                }
                // 
                @media screen and (max-width:950px){
                    @include flexbox();
                    @include flex-direction(column-reverse);
                    @include justify-content(space-around);
                }
            }
            // margin-section
            @media screen and (max-width:950px){
                margin-top: 30px;                
            }
        }
        //
        .seventhRow{
            margin-top: 80px;
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            @media screen and(max-width:768px){
                margin-top: 30px;
            }
            .card{
                &.row{
                    margin: 20px -13px 0 -13px;
                    @media screen and(max-width:768px){
                        margin: 20px -5px 0 -5px;
                    }
                }
                .col-lg-3, .col-md-4, .col-sm-6{
                    padding: 13px;
                }
                .col-xs-6{
                    padding: 5px 5px 5px 5px;
                }
                .items{
                    border-radius: 10px;
                    display: block;
                    .top{
                        position: relative;
                        img{
                            width: 100%;
                            height: auto;
                        }
                    }
                    .down{
                        background-color: #FFFFFF;
                        width: 100%;
                        height: 60px;
                        border-radius: 0 0 10px 10px;
                        @include flexbox();
                        @include flex-direction(column);
                        @include justify-content(center);
                        @include align-items(center);
                        position: relative;
                        p{
                            flex-direction: column;
                            font-size: 100%;
                            color: #5E4417;
                            @media screen and(max-width:550px){
                                font-size: 75%;
                            }
                        }
                        span.triangle-top{
                            position: absolute;
                            left: 0;
                            top: 0;
                            // 
                            width: 0;
                            height: 0;
                            border-top: 24px solid transparent;
                            border-right: 24px solid #FFFFFF;
                        }
                        span.triangle-down{
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            // 
                            width: 0;
                            height: 0;
                            border-bottom: 24px solid transparent;
                            border-left: 24px solid #FFFFFF;
                        }
                    }
                    &.card-1{
                        background-color: #F4C9E1;
                        border: 4px solid #F4C9E1;
                        span.triangle-top{
                            background-color: #F4C9E1;
                        }
                        span.triangle-down{
                            background-color: #F4C9E1;
                        }
                    }
                    &.card-2{
                        background-color: #A0EAA9;
                        border: 4px solid #A0EAA9;
                        span.triangle-top{
                            background-color: #A0EAA9;
                        }
                        span.triangle-down{
                            background-color: #A0EAA9;
                        }      
                    }
                    &.card-3{
                        background-color: #FFE579;
                        border: 4px solid #FFE579;
                        span.triangle-top{
                            background-color: #FFE579;
                        }
                        span.triangle-down{
                            background-color: #FFE579;
                        }  
                    }
                    &.card-4{
                        background-color: #CDBED8;
                        border: 4px solid #CDBED8;
                        span.triangle-top{
                            background-color: #CDBED8;
                        }
                        span.triangle-down{
                            background-color: #CDBED8;
                        }  
                    }
                    &.card-5{
                        background-color: #A2ECF4;
                        border: 4px solid #A2ECF4;
                        span.triangle-top{
                            background-color: #A2ECF4;
                        }
                        span.triangle-down{
                            background-color: #A2ECF4;
                        }  
                    }
                    &.card-6{
                        background-color: #FFC888;
                        border: 4px solid #FFC888;
                        span.triangle-top{
                            background-color: #FFC888;
                        }
                        span.triangle-down{
                            background-color: #FFC888;
                        } 
                    }
                    &.card-7{
                        background-color: #D9CCB8;
                        border: 4px solid #D9CCB8;
                        span.triangle-top{
                            background-color: #D9CCB8;
                        }
                        span.triangle-down{
                            background-color: #D9CCB8;
                        } 
                    }
                    &.card-8{
                        background-color: #FFB1BD;
                        border: 4px solid #FFB1BD;
                        span.triangle-top{
                            background-color: #FFB1BD;
                        }
                        span.triangle-down{
                            background-color: #FFB1BD;
                        } 
                    }
                }
                
            }
        }      
    }
}

